import React, { Suspense, lazy, useEffect } from "react";
import Footer from "./Components/Footer/Footer2";
import { Routes, Route } from "react-router-dom";

// Lazy load components
const HomePage = lazy(() => import("./Pages/HomePage/HomePage"));
const AboutUs = lazy(() => import("./Pages/Aboutus/AboutUs"));
const ContactUs = lazy(() => import("./Components/Contact/ContactUs"));
const ServicesSap = lazy(() => import("./Components/SAP/ServicesSap"));
const MobileApp = lazy(() => import("./Components/MobileApp/MobileApp"));
const WebDev = lazy(() => import("./Components/WebDevelopment/WebDev"));
const Csr = lazy(() => import("./Components/CSR/CSR"));
const Blog = lazy(() => import("./Components/Blogs/Blogs"));
const LazyEmergingTech = lazy(() =>
  import("./Components/EmergingTech/EmergingTech")
);

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="SAP" element={<ServicesSap />} />
          <Route path="MobileApp" element={<MobileApp />} />
          <Route path="WebDevelopment" element={<WebDev />} />
          <Route path="CSR" element={<Csr />} />
          <Route path="Blog" element={<Blog />} />
          <Route path="Emergingtechnology" element={<LazyEmergingTech />} />
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
};

export default App;
